video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }

  .LazyHero {
    background-color: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.25)) !important;
    /* background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.25)),url('/images/hero7.png') center center/cover no-repeat; */
    margin: 0px !important;
    height: 35vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .header-1 {
    color: #fff !important;
    font-size: 48px !important;
  }
  
  .header-2 {
    color: #fff !important;
    font-size: 16px !important;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif !important;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  .btn-container {
    margin-top: 5px;
  }

  .moon {
    position: absolute;
    margin: auto;
    top: 5%;
    right: 10%;
    width: 50px;
    height: 50px;
    background:#159;
    border-radius: 50%;
    box-shadow:0 0 50px gold,0 0 150px gold, inset 0 20px 20px 40px whitesmoke, inset 30px 0 35px 50px ivory;
  }
  
  @media(min-width: 800px) {
    .hero-container {
      height: 60vh;
    }
    .header-1 {
      font-size: 64px !important;
    }
  
    .header-2 {
      font-size: 18px !important;
    }
  }

