.ratio-line-canvas {
    width: 100%;
    height: 100%;
    max-height: 30px;
    padding: 0px !important;
    margin-bottom: -10px;
}
.ratio-line-table{
    height: 100%;
    border-width: 0px !important;
    padding: 0px !important;
}
.table-cell-canvas{
    height: 100%;
    border-width: 0px !important;
    padding: 0px !important;
}
.ratio-line-row{
    height: 100%;
    border-width: 0px !important;
    padding: 0px !important;
}
.table-cell-min {
    height: 100%;
    border-width: 0px !important;
    padding: 0px !important;
    text-align: left !important;
}
.table-cell-max {
    height: 100%;
    border-width: 0px !important;
    padding: 0px !important;
    text-align: right !important;
}