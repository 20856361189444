.footer-ads {
  background-color: black;
  margin: auto;
  min-width: 300px;
}

@media(min-width: 800px) {
  .footer-ads {
    background-color: black;
    margin: auto;
    min-width: 300px;
  }
}