body {
    font-size: 15px;
}

/* Solve the space problem on the right
 * if the content is shorter then the parent width
 */
div.category {
    display: block !important;
}

.search-content {
    width: auto;
    background-color: lightgrey;
}

.category-header {
    width: auto;
    height: auto;
    background-color: lightgrey;
    padding: 0px;
}

.category-content {
    width: auto;
    color: black;
    font-size: normal;
    padding: 5px;
}

.link-header{
    width: auto;
    background-color: lightseagreen;
    font-size: normal;
    padding: 0px;
}

.result-header {
    width: auto;
    height: auto;
    background-color: white;
    padding: 0px;
}

.result-content {
    width: auto;
    font-size: small;
    color: dodgerblue;
    padding: 0px;
}

.input{
    width: 100%;
    max-width: 500px;
} 

.wrapper {
    position:relative;
    width: 100%;
    max-width: 500px;
    margin: auto;
  }

.search-bar-us .ui.input>input{
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    padding-left: 3rem !important;

}

.iconfront {
    height: 1.75rem;
    width: 2rem;
    /* background-color: red; */
    padding: 4px;
    position: absolute;
    box-sizing:border-box;
    top:40%;
    left:0.5rem;
    transform: translateY(-50%);
    z-index: 20;
}

/* .input {
height: 50px;
box-sizing:border-box;
padding-left: 1.5rem;
} */