.tabDiv {
    white-space: nowrap;
    width: 100%;
    margin: auto;
    /* border-bottom: 3px solid rgba(34,36,38,.14); */
    max-width: 800px;
    /* display: inline-block; */
    overflow-x: scroll;
    text-align: center;
  }
  
/* Hide scrollbar for Chrome, Safari and Opera */
.tabDiv::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tabDiv {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}
