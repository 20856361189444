.table-widget {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  margin-left: 0.5em !important;
  margin-right: 0.5em !important;
  min-width: 320px !important;
  /*
  max-width: 300px !important;
  min-height: 195px;
  min-height: 195px;
  */
}

.table-title {
  padding: 0px !important;
  margin: 0px !important;
  line-height: 1.5em;
  font-size: 1.1em;
}

.table-title-right {
  text-align: right !important;
  padding: 0px !important;
  margin: 0px !important;
  line-height: 1.5em;
  font-size: 1.1em;
}

.table-header {
  padding: 0px !important;
  margin: 0px !important;
  line-height: 1.5em;
  font-size: 1.1em;
}

.table-header-right {
  text-align: right !important;
  padding: 0px !important;
  margin: 0px !important;
  line-height: 1.5em;
  font-size: 1.1em;
}

.table-body {
  padding: 0px !important;
  margin: 0px !important;
  line-height: 1.5em;
  font-size: 1.1em;
}

.table-body-right {
  text-align: right !important;
  padding: 0px !important;
  margin: 0px !important;
  line-height: 1.5em;
  font-size: 1.1em;
}

.table-footer {
  padding: 0px !important;
  margin: 0px !important;
  line-height: 1.2em;
  font-size: 1em;
  background-color: rgb(249, 250, 251);
  color: gray;
  text-align: center !important;
}

@media(min-width: 800px) {
  .table-body {
    padding: 0px !important;
    margin: 0px !important;
    line-height: 1.5em;
    font-size: 1.1em;
  }
}