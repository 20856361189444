.general_large_banner {
  min-width: 320px !important;
  width: 100% !important;
  height: 100px !important;
}

@media(min-width: 470px) {
  .general_large_banner {
    min-width: 468px !important;
    width: 100% !important;
    height: 100px !important;
  }
}

@media(min-width: 730px) {
  .general_large_banner {
    min-width: 728px !important;
    width: 100% !important;
    height: 200px !important;
  }
}