.inTableChartMobile {
  position: -webkit-sticky;
  position: sticky;
  left: 2.5%;
}

.inTableChart {
  margin: auto;
}


.divbody {
  background-color: lightblue;
}
